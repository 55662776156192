import { render, staticRenderFns } from "./FormBuilder.vue?vue&type=template&id=b87a5bc0"
import script from "./FormBuilder.vue?vue&type=script&lang=js"
export * from "./FormBuilder.vue?vue&type=script&lang=js"
import style0 from "./FormBuilder.vue?vue&type=style&index=0&id=b87a5bc0&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports